// extracted by mini-css-extract-plugin
export var department = "Jobs-module--department--mnBU6";
export var jobCard = "Jobs-module--job-card--Qe1dC";
export var jobLink = "Jobs-module--job-link--SFVld";
export var jobLocation = "Jobs-module--job-location--bBce4";
export var jobTimeline = "Jobs-module--job-timeline--Tq1+T";
export var jobTitle = "Jobs-module--job-title--4D9IC";
export var jobTitleLink = "Jobs-module--job-title-link--gfUTi";
export var noJobs = "Jobs-module--no-jobs--qOGLs";
export var positions = "Jobs-module--positions--8Snm7";
export var positionsBanner = "Jobs-module--positions-banner--clzO0";
export var positionsTitle = "Jobs-module--positions-title--Gic9i";